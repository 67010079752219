import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { sessionDetails } from "@/api";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    meta: { title: "Dashboard", requireAuth: true },
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    beforeEnter: async (to, from, next) => {
      const session = await sessionDetails();
      const isAuthenticated = session.status === 200 || false;
      if (isAuthenticated) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/new-submission",
    name: "NewFormSubmission",
    meta: { parent: "Forms", title: "New Submission", requireAuth: true },
    component: () => import("@/views/NewForm.vue"),
  },
  {
    path: "/new-submission/:slug",
    name: "NewSubmission",
    meta: { parent: "Forms", title: "New Submission", requireAuth: true },
    component: () => import("@/views/FormEntry.vue"),
    children: [
      {
        path: "",
        redirect: { name: "Information" },
      },
      {
        path: "information",
        name: "Information",
        meta: {
          parent: "Forms",
          title: "New Submission",
          subtitle: "Information",
          requireAuth: true,
        },
        component: () => import("@/views/EntryInformation.vue"),
      },
      {
        path: "approvers",
        name: "Approvers",
        meta: {
          parent: "Forms",
          title: "New Submission",
          subtitle: "Approvers",
          requireAuth: true,
        },
        component: () => import("@/views/ApprovalLayer.vue"),
        beforeEnter: async (to, from, next) => {
          if (store.state.draftForm) {
            next();
          } else {
            next(false);
          }
        },
      },
      {
        path: "submit",
        name: "Submit",
        meta: {
          parent: "Forms",
          title: "New Submission",
          subtitle: "Submit",
          requireAuth: true,
        },
        component: () => import("@/views/SubmitForm.vue"),
        // beforeEnter: async (to, from, next) => {
        //   if (store.state.draftForm) {
        //     next();
        //   } else {
        //     next(false);
        //   }
        // },
      },
    ],
  },
  {
    path: "/activities",
    name: "PendingForms",
    meta: { parent: "Forms", title: "Activities", requireAuth: true },
    children: [
      {
        path: "",
        query: { status: "pending", page: 1, per_page: 10 },
        component: () => import("@/views/PendingForm.vue"),
      },
      {
        path: "view/:slug/:id",
        name: "ViewPendingEntry",
        meta: { parent: "Forms", title: "Activities", requireAuth: true },
        component: () => import("@/views/ViewEntry.vue"),
      },
    ],
  },
  {
    path: "/history",
    name: "historyForm",
    meta: { parent: "Forms", title: "History", requireAuth: true },
    children: [
      {
        path: "",
        query: { status: "Past Submissions", page: 1, per_page: 10 },
        component: () => import("@/views/HistoryForm.vue"),
      },
      {
        path: "view/:slug/:id",
        name: "ViewPastEntry",
        meta: { parent: "Forms", title: "History", requireAuth: true },
        component: () => import("@/views/ViewEntry.vue"),
      },
    ],
  },
  {
    path: "/drafts",
    name: "draftForms",
    meta: { parent: "Forms", title: "Drafts", requireAuth: true },
    component: () => import("@/views/DraftForm.vue"),
  },
  {
    path: "/auth/onetime/:slug",
    meta: { parent: "Forms", title: "One Time Auth", requireAuth: false },
    component: () => import("@/views/OneTimeAuth.vue"),
    children: [
      {
        path: "",
        name: "OneTimeAuth",
        component: () => import("@/views/EntryInformation.vue"),
      },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: "/", meta: { requireAuth: true } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

//TODO The checking should happen whenever a request fails with 401 unauthorized status code, the token will be deleted and the user will be redirected to the login page

router.beforeEach(async (to, from, next) => {
  if (to.meta.requireAuth && !store.state.user) {
    const session = await sessionDetails();
    const isAuthenticated = session.status === 200 || false;
    if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
    else if (to.name === "Login" && isAuthenticated) next(from);
    else next();
  }
  next();
});

export default router;

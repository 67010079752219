<template>
  <div
    :class="[
      'flex flex-row items-center justify-between w-full mx-16 pt-10 pb-4 pr-32',
      !sideNavOpen ? 'pl-24' : 'pl-80',
    ]"
  >
    <p class="text-xl font-bold">{{ $route.meta.parent || $route.name }}</p>
    <div class="flex flex-row items-center">
      <FontAwesomeIcon
        class="ml-2 cursor-pointer text-2xl text-eos-blue-light relative"
        icon="fa-solid fa-bell"
      />
      <span
        class="absolute rounded-full text-xs text-white mb-2 ml-1 bg-red-500 h-4 w-4"
        >1</span
      >
      <img
        class="ml-4 w-12 h-12 rounded-full object-cover shadow-md"
        src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        alt="User Avatar"
      />
      <p class="ml-2 font-bold">Abdalla Ayman</p>
      <FontAwesomeIcon
        class="ml-2 cursor-pointer"
        icon="fa-solid fa-chevron-down"
      />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapState } from "vuex";
export default {
  name: "TopNav",
  components: { FontAwesomeIcon },
  computed: mapState(["sideNavOpen"]),
};
</script>

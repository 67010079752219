<template>
  <div
    :class="[
      'bg-eos-blue flex flex-col items-center justify-between fixed h-full max-w-xs rounded-r-3xl transition-all duration-150',
      sideNavOpen ? 'w-full' : 'w-24',
    ]"
  >
    <div class="flex flex-col w-full items-center">
      <br />
      <img
        :class="[
          'w-52 transition-all duration-75',
          !sideNavOpen && 'invisible',
        ]"
        src="@/assets/icons/ELM-logo-white.png"
        alt="ELMLAB LOGO"
      />
      <div class="w-full">
        <font-awesome-icon
          @click="toggleSideNav"
          class="bg-white relative float-right left-3 p-3 rounded-lg text-eos-blue shadow-md cursor-pointer"
          :class="!sideNavOpen && 'transform rotate-180'"
          icon="fa-solid fa-chevron-left"
        />
      </div>
      <ul class="w-full px-10">
        <li
          v-for="menu in sideNavMenu"
          :key="menu.title"
          class="flex flex-col gap-4 items-start text-white"
        >
          <span :class="['w-full text-left', !sideNavOpen && 'invisible']">
            <p class="text-white text-xl font-bold'">
              {{ menu.title }}
            </p>
            <hr class="border-t border-gray-600 w-full" />
          </span>
          <router-link
            :to="{ path: item.to, query: item?.query }"
            class="flex flex-row items-center w-full"
            :active-class="
              sideNavOpen
                ? 'bg-white text-eos-blue p-2 rounded-bl-xl rounded-tr-xl shadow-xl font-bold'
                : ''
            "
            v-for="item in menu.items"
            :key="item.name"
          >
            <font-awesome-icon
              :class="[
                'text-xl transition-all duration-75',
                sideNavOpen ? 'mr-4' : 'mr-0',
                !sideNavOpen &&
                  $route.path == item.to &&
                  'bg-white p-2 text-eos-blue rounded-bl-xl rounded-tr-xl shadow-xl',
              ]"
              :icon="item.icon"
            />
            <span
              :class="[
                'transition-all duration-75',
                !sideNavOpen && 'invisible',
              ]"
            >
              {{ item.name }}
            </span>
          </router-link>

          <br />
        </li>
      </ul>
    </div>
    <div
      @click="logout"
      class="flex flex-row items-center w-full text-white px-10 pb-10 hover:cursor-pointer"
    >
      <font-awesome-icon
        :class="[
          'text-xl transition-all duration-75',
          sideNavOpen ? 'mr-4' : 'mr-0',
        ]"
        icon="fa-solid fa-right-from-bracket"
      />
      <span
        :class="['transition-all duration-75', !sideNavOpen && 'invisible']"
      >
        Logout
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ref } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "SideNav",
  components: { FontAwesomeIcon },
  computed: mapState(["sideNavSize", "sideNavOpen"]),
  setup() {
    const sideNavMenu = ref([
      {
        title: "General",
        items: [
          {
            name: "Dashboard",
            icon: ["fa-solid", "fa-gauge"],
            to: "/",
          },
        ],
      },
      {
        title: "Forms",
        items: [
          {
            name: "New Submission",
            icon: ["fa-solid", "fa-circle-plus"],
            to: "/new-submission",
          },
          {
            name: "Activities",
            icon: ["fa-solid", "fa-spinner"],
            query: { status: "pending", page: 1, per_page: 10 },
            to: "/activities",
          },
          {
            name: "History",
            icon: ["fa-solid", "fa-clock-rotate-left"],
            query: { status: "pending", page: 1, per_page: 10 },
            to: "/history",
          },
          {
            name: "Drafts",
            icon: ["fa-solid", "fa-clipboard-list"],
            query: { status: "pending", page: 1, per_page: 10 },
            to: "/drafts",
          },
        ],
      },
      {
        title: "Reports",
        items: [
          {
            name: "Staff Claim",
            icon: ["fa-solid", "fa-user-tie"],
            to: "report/staff-claim",
          },
          {
            name: "Corporate Claim",
            icon: ["fa-solid", "fa-building"],
            to: "report/corporate-claim",
          },
        ],
      },
    ]);
    return { sideNavMenu };
  },
  methods: {
    toggleSideNav() {
      this.$store.commit("toggleSideNav");
    },
    ...mapActions(["logout"]),
  },
};
</script>

<style></style>

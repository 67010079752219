import "./index.css";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { createApp } from "vue";
import vueClickAway from "vue3-click-away";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faLock,
  faInfo,
  faFlag,
  faBell,
  faGauge,
  faCheck,
  faPaste,
  faUserTie,
  faSpinner,
  faBuilding,
  faStopwatch,
  faAngleRight,
  faCirclePlus,
  faChevronLeft,
  faCircleXmark,
  faRotateRight,
  faChevronDown,
  faChevronRight,
  faSheetPlastic,
  faClipboardList,
  faClockRotateLeft,
  faMagnifyingGlass,
  faFileCircleXmark,
  faFileCircleCheck,
  faRightFromBracket,
  faFileCircleQuestion,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faUser,
  faLock,
  faInfo,
  faFlag,
  faBell,
  faGauge,
  faCheck,
  faPaste,
  faUserTie,
  faSpinner,
  faBuilding,
  faStopwatch,
  faAngleRight,
  faCirclePlus,
  faChevronLeft,
  faCircleXmark,
  faRotateRight,
  faChevronDown,
  faChevronRight,
  faSheetPlastic,
  faClipboardList,
  faClockRotateLeft,
  faMagnifyingGlass,
  faFileCircleXmark,
  faFileCircleCheck,
  faRightFromBracket,
  faFileCircleQuestion,
  faArrowUpFromBracket
);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vueClickAway);
app.mount("#app");

<template>
  <side-nav v-if="!['Login', 'OneTimeAuth'].includes($route.name)" />
  <top-nav v-if="!['Login', 'OneTimeAuth'].includes($route.name)" />
  <router-view
    :class="[
      !['Login', 'OneTimeAuth'].includes($route.name) && 'mx-16',
      !['Login', 'OneTimeAuth'].includes($route.name) &&
        (!sideNavOpen ? 'pl-24' : 'pl-80'),
    ]"
  ></router-view>
</template>

<script>
import SideNav from "./components/SideNav.vue";
import { mapState } from "vuex";
import TopNav from "./components/TopNav.vue";
export default {
  name: "App",
  components: { SideNav, TopNav },
  computed: mapState(["sideNavOpen"]),
  created() {
    // this.$store.dispatch("checkSession");
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>

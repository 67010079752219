import router from "@/router";
// import store from "@/store";
import axios from "axios";

// axios.defaults.baseURL = "https://iport2-prod-ctrl.tekmarkgroup.com/";
axios.defaults.baseURL = "https://admin.eos.elmlab.my/";

// if (localStorage.getItem("token")) {
//   axios.defaults.headers.common["Authorization"] =
//     "token " + localStorage.getItem("token");
// } else if (sessionStorage.getItem("token")) {
//   axios.defaults.headers.common["Authorization"] =
//     "token " + sessionStorage.getItem("token");
// } else {
//   axios.defaults.headers.common["Authorization"] = "";
// }

const getHeaders = () => {
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token") || null;
  if (token) {
    return {
      Authorization: `token ${token}`,
    };
  }
};

export const getForms = async () => {
  return axios
    .get("form/generator/published/", {
      headers: getHeaders(),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};

export const getForm = async (slug) => {
  return axios
    .get(`form/generator/${slug}/`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    });
};

export const getComputedForm = async (computer, payload) => {
  return axios
    .post(computer, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    });
};

export const validateEntry = async (validator, payload) => {
  return axios
    .post(validator, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data.valid;
    });
};

export const submitEntry = async (slug, payload, id = null) => {
  return axios
    .post(`form/entries/${slug}/${(id && id + "/") || ""}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    });
};

export const getPendingEntries = async (page, perPage) => {
  return axios
    .get(
      `form/entries/action/?page=${page}&page_size=${perPage}&search=&status__in=pending`,
      {
        headers: getHeaders(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

export const getPendingEntry = async (slug, id) => {
  return axios
    .get(`form/entries/action/${slug}/${id}/`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    });
};

export const getDraftEntries = async (page, perPage) => {
  return axios
    .get(
      `form/entries/entrylist/?status_in=draft&page_size=${perPage}&page=${page}&search=&submitted_for__username=`,
      {
        headers: getHeaders(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

export const getEntry = async (slug, id) => {
  return axios
    .get(`form/entries/${slug}/${id}/`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    });
};

export const updateEntry = async (slug, id, payload) => {
//   let body = JSON.stringify({
//     data: payload,
//     submitted_for_username,
// })

// if(!isNextStep){
//     body = JSON.stringify({
//         data: payload,
//         submitted_for_username,
//         status: 'draft'
//     })

// }
  return axios
    .put(`form/entries/${slug}/${id}/`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    });
};

export const getPastActivities = async (page, perPage) => {
  return axios
    .get(
      `form/entries/entrylist/?status_notin=pending,waiting,draft&page_size=${perPage}&page=${page}&search=&submitted_for__username=`,
      {
        headers: getHeaders(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

export const getPastSubmissions = async (page, perPage) => {
  return axios
    .get(
      `form/entries/entrylist/?status_notin=pending,approved,draft&page_size=${perPage}&page=${page}&search=&submitted_for__username=admin`,
      {
        headers: getHeaders(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

export const getOngoingForms = async (page, perPage, username = "admin") => {
  return axios
    .get(
      `form/entries/entrylist/?page=${page}&page_size=${perPage}&search=&status_in=pending%2Capproved&submitted_for__username=${username}`,
      {
        headers: getHeaders(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

export const login = async (username, password) => {
  return axios
    .post("user/login/", { username, password }, { headers: getHeaders() })
    .then((response) => {
      axios.defaults.headers.common["Authorization"] =
        "token " + response.data.token;
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};

export const logout = async () => {
  await axios.post("user/logout/", {}, { headers: getHeaders() });
  localStorage.removeItem("token");
  sessionStorage.removeItem("token");
  delete axios.defaults.headers.common["Authorization"];
  router.replace({ name: "Login" });
};

export const sessionDetails = async () => {
  return axios
    .get("account/me/", { headers: getHeaders() })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};

export const magicLinkAuth = async (token, email) => {
  return axios
    .get(`onetime/verify/?token=${token}&email=${email}`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    })
    .then(() => {
      return { success: true };
    })
    .catch((err) => {
      let returnData = { success: false, error: err };
      console.log(err);
      return returnData;
    });
};

import { createStore } from "vuex";
import {
  getForm,
  getForms,
  login,
  logout,
  sessionDetails,
} from "@/api/index.js";
import createPersistedState from "vuex-persistedstate";
import router from "@/router";

const store = createStore({
  state() {
    return {
      user: null,
      sideNavOpen: true,
      forms: [],
      selectedForm: null,
      draftForm: null
    };
  },
  getters: {},
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    toggleSideNav(state) {
      state.sideNavOpen = !state.sideNavOpen;
    },
    setForms(state, forms) {
      state.forms = forms;
    },
    setForm(state, form) {
      state.selectedForm = form;
    },
    setDraftForm(state, form) {
      state.draftForm = form;
    }
  },
  actions: {
    async login({ commit }, { username, password, rememberMe }) {
      const data = await login(username, password);

      console.log(data.token);

      if (data.token) {
        if (rememberMe) {
          localStorage.setItem("token", data.token);
        } else {
          sessionStorage.setItem("token", data.token);
        }

        commit("setUser", { username });
        return true;
      }
      return false;
    },
    async logout({ commit }) {
      await logout();

      localStorage.removeItem("token");
      sessionStorage.removeItem("token");

      commit("setUser", null);
    },
    async checkSession({ commit }) {
      // try {
      const response = await sessionDetails();

      if (response.status === 200) {
        commit("setUser", response);
      } else {
        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
        router.replace({ name: "Login" });
        commit("setUser", null);
      }
    },
    async getForms({ commit }) {
      const forms = await getForms();

      console.log(forms.status);

      commit(
        "setForms",
        forms.data.sort((form1, form2) => {
          return form1.position - form2.position;
        })
      );
    },
    async getForm({ commit }, slug) {
      const form = await getForm(slug);
      commit("setForm", form);
      return form;
    },
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});

export default store;
